import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from 'components/dist/atoms/Avatar';
import Button from 'components/dist/atoms/Button';
import Drawer from 'components/dist/atoms/Drawer';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FileIcon from 'components/dist/molecules/FileIcon';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { useRouter } from "next/router";
import { NextLinkComposed } from "src/components/common/next-link-composed";
import { QUERY_PARAM_NEEDS_LIST_DIALOG } from 'src/constants/form-element';
import { QUERY_PARAM_SOURCES_AND_USES } from 'src/constants/loan';
import { Route } from "src/constants/ui";
import { useMessagesContext } from 'src/contexts/messages-context';
import { useLoanStages } from "src/hooks/use-loan-stages";
import { useUser } from 'src/hooks/use-user';
import { getLoan, updateLoanPhase } from "src/slices/loan";
import { useDispatch } from "src/store";
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { Loan } from "src/types/loan"

import { useLoanOverviewPackage } from '../tabs/overview/v2/loan-overview-package/loan-overview-package.hook';
import { LoanOverviewPackageDropdownItems } from '../tabs/overview/v2/loan-overview-package/loan-overview-package-dropdown-items';
import { LoanOverviewPackageModals } from '../tabs/overview/v2/loan-overview-package/loan-overview-package-modals';

interface LoanActionsMenuProps {
    loan: Loan;
    formElements: FormElementV2ResponseDtoExtended[];
    onCopy: (loanId: string) => void;
}

const preventDefault = (e: React.MouseEvent) => {
    e.preventDefault();
}

export const LoanActionsMenu = (props: LoanActionsMenuProps) => {
    const dispatch = useDispatch();
    const userState = useUser();
    const messagesContext = useMessagesContext();
    const loanPackageState = useLoanOverviewPackage({
        formElements: props.formElements,
        loan: props.loan
    });
    const router = useRouter();
    const { firstArchivePhase } = useLoanStages({
        companyId: props.loan.lender.id
    });
    const isTablet = useMediaQuery('(min-width:768px)');
    const isDesktop = useMediaQuery('(min-width:1024px)');

    const handlePhaseOnClick = async () => {
        try {
            await dispatch(updateLoanPhase({
                loanId: props.loan.id,
                phaseId: firstArchivePhase.id,
            }))
        } catch { }
        await dispatch(getLoan(props.loan.id));
    };

    const onSendMessageClick = () => {
        messagesContext.onSendMessageClick([], {
            loanId: props.loan.id,
            recipients: [],
            shoeboxItemsIds: []
        });
    };

    // create list of actions from dropdown menu items
    const actions = [
        {
            label: 'Edit Loan',
            icon: <Icon name="Edit" width={20} height={20} />,
            onClick: () => router.push({
                pathname: Route.EDIT_SINGLE_LOAN,
                query: {
                    loanId: props.loan.id
                }
            })
        },
        {
            label: 'Add Member',
            icon: <Icon name="Group" strokeWidth={1.5} width={20} height={20} />,
            onClick: () => router.push({
                pathname: Route.ADD_LOAN_LENDER_ROLE,
                query: {
                    loanId: props.loan.id
                }
            })
        },
        {
            label: 'Sources and Uses',
            icon: <Icon strokeWidth={1.5} name="MoneySquare" width={20} height={20} />,
            onClick: () => router.push({
                pathname: Route.SINGLE_LOAN,
                query: {
                    loanId: props.loan.id,
                    [QUERY_PARAM_SOURCES_AND_USES]: true,
                }
            })
        },
        {
            label: 'Needs List',
            icon: <Icon strokeWidth={1.5} name="MultiplePagesEmpty" width={20} height={20} />,
            onClick: () => {
                router.push({
                    pathname: Route.SINGLE_LOAN,
                    query: {
                        loanId: props.loan.id,
                        [QUERY_PARAM_NEEDS_LIST_DIALOG]: true,
                    }
                })
            }
        },
        {
            label: 'Archive Loan',
            icon: <Icon name="Archive" width={20} height={20} />,
            disabled: !firstArchivePhase?.id,
            onClick: () => handlePhaseOnClick
        },
        {
            label: 'Download PDF',
            icon: <FileIcon fileName="file.pdf" />,
            onClick: (event) => loanPackageState.onDownloadPdfClick(event),
            disabled: loanPackageState.isDownloadPdfPackageDisabled
        },
        {
            label: 'Export to Ventures',
            icon: <Avatar size='xs'>
                <Avatar.Fallback className="bg-[#40922B] text-white">
                    V
                </Avatar.Fallback>
            </Avatar>,
            onClick: () => loanPackageState.onExportToVenturesClick(),
            disabled: loanPackageState.isExportToVenturesDisabled
        },
        {
            label: 'Download ZIP',
            icon: <FileIcon fileName="file.zip" />,
            onClick: (event) => loanPackageState.onDownloadZipClick(event),
            disabled: loanPackageState.isDownloadZipPackageDisabled
        }
    ]
    return <div onClick={preventDefault}>
        <ScreenSizeDropdownMenu>
            <ScreenSizeDropdownMenu.Trigger asChild>
                <Button
                    className="w-7 h-7 aspect-square p-0 rounded-full"
                    size="sm"
                    variant="ghost">
                    <Icon name="MoreVert" width={24} height={24} strokeWidth={2} />
                </Button>
            </ScreenSizeDropdownMenu.Trigger>
            <ScreenSizeDropdownMenu.Content
                align="start"
                side="bottom"
                className='w-full sm:w-72 max-w-full p-2'
                alignOffset={0}
                withPortal>
                {!isTablet && actions.map((action) => (<Drawer.Close
                    key={action.label}
                    asChild
                >
                    <div
                        tabIndex={0}
                        role="menuitem"
                        onKeyUp={(event) => action.onClick(event)}
                        onClick={(event) => action.onClick(event)}
                        className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2"
                    >
                        {action.icon}
                        <Text size="sm" weight="medium">{action.label}</Text>
                    </div>
                </Drawer.Close>))
                }
                {isTablet && <>
                    {userState.isLender && <DropdownMenu.Item asChild>
                        <NextLinkComposed
                            to={{
                                pathname: Route.EDIT_SINGLE_LOAN,
                                query: {
                                    loanId: props.loan.id
                                }
                            }}>
                            <Stack row space="sm">
                                <Icon name="EditPencil" width={20} height={20} />
                                Edit Loan
                            </Stack>
                        </NextLinkComposed>
                    </DropdownMenu.Item>}
                    {userState.isLender && <DropdownMenu.Item asChild>
                        <NextLinkComposed
                            to={{
                                pathname: Route.ADD_LOAN_LENDER_ROLE,
                                query: {
                                    loanId: props.loan.id
                                }
                            }}>
                            <Stack row space="sm">
                                <Icon name="Group" strokeWidth={1.5} width={20} height={20} />
                                Add Member
                            </Stack>
                        </NextLinkComposed>
                    </DropdownMenu.Item>}

                    {userState.isLender && <>
                        <DropdownMenu.Item asChild>
                            <NextLinkComposed
                                to={{
                                    pathname: Route.SINGLE_LOAN,
                                    query: {
                                        loanId: props.loan.id,
                                        [QUERY_PARAM_SOURCES_AND_USES]: true,
                                    }
                                }}>
                                <Stack row space="sm">
                                    <Icon strokeWidth={1.5} name="MoneySquare" width={20} height={20} />
                                    Sources and Uses
                                </Stack>
                            </NextLinkComposed>
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator />
                    </>}
                    <DropdownMenu.Item asChild>
                        <NextLinkComposed
                            to={{
                                pathname: Route.SINGLE_LOAN,
                                query: {
                                    loanId: props.loan.id,
                                    [QUERY_PARAM_NEEDS_LIST_DIALOG]: true,
                                }
                            }}>
                            <Stack row space="sm">
                                <Icon strokeWidth={1.5} name="MultiplePagesEmpty" width={20} height={20} />
                                Needs List
                            </Stack>
                        </NextLinkComposed>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item
                        onSelect={onSendMessageClick}>
                        <Stack row space="sm">
                            <Icon name="MessageText" width={20} height={20} />
                            Send a message
                        </Stack>
                    </DropdownMenu.Item>
                    {userState.isLender && props.loan.loanPhase.id !== firstArchivePhase?.id && <DropdownMenu.Item
                        onClick={(e) => e.stopPropagation()}
                        disabled={!firstArchivePhase?.id}
                        onSelect={handlePhaseOnClick}>
                        <Stack row space="sm">
                            <Icon name="Archive" width={20} height={20} />
                            Archive Loan
                        </Stack>
                    </DropdownMenu.Item>
                    }
                    {userState.isLender && <>
                        {isDesktop ? <DropdownMenu.Sub>
                            <DropdownMenu.SubTrigger>
                                <Stack row space="sm" className='flex-1'>
                                    <Icon name="Suitcase" strokeWidth={1.5} width={20} height={20} />
                                    Package and Export
                                </Stack>
                            </DropdownMenu.SubTrigger>
                            <DropdownMenu.SubContent>
                                <LoanOverviewPackageDropdownItems
                                    loan={props.loan}
                                    isDownloadPdfPackageDisabled={loanPackageState.isDownloadPdfPackageDisabled}
                                    onDownloadPdfClick={loanPackageState.onDownloadPdfClick}
                                    isPackageExist={loanPackageState.isPackageExist}
                                    isVenturesEnabled={loanPackageState.isVenturesEnabled}
                                    isExportToVenturesDisabled={loanPackageState.isExportToVenturesDisabled}
                                    onExportToVenturesClick={loanPackageState.onExportToVenturesClick}
                                    isDownloadZipPackageDisabled={loanPackageState.isDownloadZipPackageDisabled}
                                    onDownloadZipClick={loanPackageState.onDownloadZipClick}
                                />
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Sub>
                            : <LoanOverviewPackageDropdownItems
                                loan={props.loan}
                                isDownloadPdfPackageDisabled={loanPackageState.isDownloadPdfPackageDisabled}
                                onDownloadPdfClick={loanPackageState.onDownloadPdfClick}
                                isPackageExist={loanPackageState.isPackageExist}
                                isVenturesEnabled={loanPackageState.isVenturesEnabled}
                                isExportToVenturesDisabled={loanPackageState.isExportToVenturesDisabled}
                                onExportToVenturesClick={loanPackageState.onExportToVenturesClick}
                                isDownloadZipPackageDisabled={loanPackageState.isDownloadZipPackageDisabled}
                                onDownloadZipClick={loanPackageState.onDownloadZipClick}
                            />
                        }
                    </>}
                </>}
            </ScreenSizeDropdownMenu.Content>
        </ScreenSizeDropdownMenu>
        {userState.isLender && <LoanOverviewPackageModals
            loan={props.loan}
            {...loanPackageState}
        />}
    </div>
}